import firebase from 'firebase/compat/app'; 
import 'firebase/compat/database';
import 'firebase/compat/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const firebaseConfig = {
    apiKey: "AIzaSyAqdCZGhvqnK3OPShENyp5Zheo2RfwRGVY",
    authDomain: "cloudpbx-2f67e.firebaseapp.com",
    databaseURL: "https://cloudpbx-2f67e-default-rtdb.firebaseio.com",
    projectId: "cloudpbx-2f67e",
    storageBucket: "cloudpbx-2f67e.appspot.com",
    messagingSenderId: "847320634949",
    appId: "1:847320634949:web:f0125eefe551c3aed6f52c"
};

const firebaseConn = firebase.initializeApp(firebaseConfig);

const auth = firebaseConn.auth();

const notify = (title) => toast.error(title, {
  position: "top-right",
  autoClose: 3000
});

const signInWithEmailAndPassword = async (email, password) => {
    try {
        await auth.signInWithEmailAndPassword(email, password);
    } catch (err) {
        // console.error(err);
        // alert(err.message);
        notify("Sai thông tin email hoặc mật khẩu !");
    }
};

const registerWithEmailAndPassword = async (name, email, password) => {
    try {
      await auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        return result.user.updateProfile({
          displayName: name,
          photoURL: "https://qtsolutions.vn/wp-content/uploads/2024/04/anh-dai-dien.jpg"
        })
      })
    } catch (err) {
    //   console.error(err);
    //    alert(err.message);
    }
};

const sendPasswordResetEmail = async (email) => {
    try {
      await auth.sendPasswordResetEmail(email);
      notify("Đã gửi ! Vào email để khôi phục lại mật khẩu !");
    } catch (err) {
      notify("Email không tồn tại trong hệ thống !")
    }
};

const logout = () => {
    auth.signOut();
};

export {firebaseConn, auth, signInWithEmailAndPassword, registerWithEmailAndPassword, sendPasswordResetEmail, logout}

// export const customerData = firebase.database().ref('customer');