import React from 'react';

function Search({search}) {

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-5">
                    <div className="form-group">
                        <input onChange={(e) => search(e.target.value)} type="text" className="form-control empty kh" id="iconified" placeholder="Nhập tên khách hàng hoặc tên dịch vụ" />
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;