import React, { useState } from 'react';
import { logout } from '../firebaseConn';
import { getAuth, updateProfile } from "firebase/auth";
import { toast } from 'react-toastify';

function Header({user, onLogo}) {

    const [name, setName] = useState(user && user.displayName);
    const [url, setURL] = useState(user && user.photoURL);
    const [change, setChange] = useState(false);
    const auth = getAuth();
    const notify = (title) => toast.success(title, {
        position: "top-right",
        autoClose: 3000
    });

    const updateClick = () => {
        updateProfile(auth.currentUser, {
            displayName: name,
            photoURL: url
        }).then(() => {
            notify("Cập nhật thành công!");
            setChange(!change);
        }).catch((err) => {
            console.log(err)
        });
    }

    return (
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">    
            <div onClick={() => onLogo()} role="button" className="logo">
                <img className="img-logo" alt="logo" src="https://qtsolutions.vn/wp-content/uploads/2022/12/cropped-qt_logo_512.png"/>
            </div>
            <span onClick={() => onLogo()} role="button" className="ml-3 text-gray-600">QUẢN LÝ KHÁCH HÀNG</span>
            <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown no-arrow">
                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Profile</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="img_show_profile d-none d-sm-block">
                                <img className="rounded-circle w-100 h-100" alt={user && user.displayName} src={user && user.photoURL}/>
                            </div>
                            <div className="container-fluid">
                                <div className="col-md-4 mb-3">
                                    <label>Full Name: </label>
                                </div>
                                <div className="col-md-8 mb-3">
                                    <input defaultValue={user && user.displayName} onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="validationDefault01" />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label>Link ảnh: </label>
                                </div>
                                <div className="col-md-8 mb-3">
                                    <input defaultValue={user && user.photoURL} onChange={(e) => setURL(e.target.value)} type="text" className="form-control" id="validationDefault01" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button onClick={() => updateClick()} type="button" className="btn btn-primary">Save changes</button>
                        </div>
                        </div>
                    </div>
                </div>  
            </li>
            <li className="nav-item dropdown no-arrow">
                <a className="nav-link dropdown-toggle" href="/" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="mr-2 d-none d-lg-inline text-gray-600">{user && user.displayName}</span>
                <img className="img-profile rounded-circle" alt={user && user.displayName} src={user && user.photoURL}/>
                </a>
                <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <button type="button" className="dropdown-item" data-toggle="modal" data-target="#exampleModal">
                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                    Profile
                </button>
                <div className="dropdown-divider" />
                <button onClick={logout} className="dropdown-item" type="submit" data-toggle="modal" data-target="#logoutModal">
                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                        Logout
                </button>
                </div>
            </li>
            </ul>
        </nav>
    );
}

export default Header;