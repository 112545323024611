import React, { useState } from 'react';
import moment from 'moment';

function DeleteTableRow({user, value, editClick, delClick, adminClick}) {

    const [showStatus, setShowStatus] = useState(false);

    const showBtnChiTiet = () => {
        if(showStatus === true) {
            return <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-secondary">Close</button>
        } else {
            return <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-primary">Detail</button>
        }
    }

    const showUser = () => {
        if(!user) {
            return;
        } else {
            return user.email
        }
    }

    const adminDel = () => {
        if(showUser() === "lytuanquang@gmail.com")
            return <button onClick={() => adminClick()} className="btn btn-outline-dark ml-2" type="button">Delete</button>
    } 

    const showChiTiet = () => {
        if(showStatus) {
            return (
                <>
                    <tr className="tr-del">
                        <th>Mã hợp đồng</th>
                        <th>ISP</th>
                        <th>Tên KH</th>
                        <th>Email khách hàng</th>
                        <th>SĐT KH</th>
                        <th>SĐT công ty</th>
                        <th>Tên công ty</th>                  
                        <th>Email hóa đơn</th>
                        <th>Địa chỉ</th>
                        <th>Ghi chú</th>
                    </tr>
                    <tr>
                        {/* <td className="btn-group mr-2 border_group" role="group">
                        {showBtnChiTiet()}
                        <button onClick={() => editClick()} className="btn btn-info ml-2" type="submit">Edit</button>
                        </td> */}
                        <td>{value.sub}</td>
                        <td>{value.isp}</td>
                        <td>{value.contact_name}</td>
                        <td>{value.cus_email}</td>
                        <td>{value.cus_phone}</td>
                        <td>{value.com_phone}</td>
                        <td>{value.com_name}</td>        
                        <td>{value.hd_email}</td>
                        <td>{value.address}</td>
                        <td><span dangerouslySetInnerHTML={{__html: value.note}} /></td>
                    </tr>
                </>
            )
        }
    }


    return (
        <>
            <tr>
                <td>{value.cus_name}</td>
                <td>{value.service}</td>
                <td>{moment(value.create_date).format("DD/MM/YYYY")}</td>
                <td>{moment(value.exp_date).format("DD/MM/YYYY")}</td>
                <td>0</td>
                <td>{value.payment === '' ? '0' : value.payment + " tháng" }</td>
                <td>{new Intl.NumberFormat().format(value.cost)}</td>
                <td>{new Intl.NumberFormat().format(value.total)}</td>
                <td>{value.ext}</td>
                <td>
                    <div className="btn-group mr-2" role="group">
                        {showBtnChiTiet()}
                        <button onClick={() => editClick()} className="btn btn-info ml-2" type="submit">Edit</button>
                        <button onClick={() => delClick()} className="btn btn-success ml-2" type="submit">Active</button>
                        {adminDel()}
                    </div>
                </td>
            </tr>
            {showChiTiet()}
        </>
    );
}
export default DeleteTableRow;