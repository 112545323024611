import React from 'react';
import { Route, Routes } from 'react-router';
import App from './App';
import Forgot from './Components/Forgot';
import Login from './Components/Login';
import Register from './Components/Register';

function RouterURL() {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<App/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot" element={<Forgot />} />
            </Routes>
        </div>
    );
}

export default RouterURL;