import React, { useEffect, useState } from 'react';
import DeleteTableRow from './DeleteTableRow';
import DeleteTableRowPhone from './DeleteTableRowPhone';
import Pag from './Pag';

function DelTable({user, dataSelect, adminSearch, searchStatus, listData, editClick, delClick, adminClick}) {

    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState([]);
    const indexOfLastNews = currentPage * dataPerPage; 
    const indexOfFirstNews = indexOfLastNews - dataPerPage; 
    const currentTodos = listData.slice(indexOfFirstNews, indexOfLastNews);

    useEffect(() => {
        if(searchStatus)
            setCurrentPage(1)
    }, [searchStatus])

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(listData.length / dataPerPage); i++) {
            pageNumbers.push(i);
        }
        setPageNumber(pageNumbers)
    },[listData, dataPerPage])

    const pageClick = (value) => {
        setCurrentPage(value)
    }

    const onChangeHandler = (e) => {
        setDataPerPage(e.target.value)
    }

    const showPag = () => {
        if(listData.length > dataPerPage) {
            if(Math.ceil(listData.length / dataPerPage) <= 5)
                return pageNumber.slice(0,5).map(value => <Pag key={value} pageClick={pageClick} value={value} currentPage={currentPage}/>)
            else
                return pageNumber.slice(0,3).map(value => <Pag key={value} pageClick={pageClick} value={value} currentPage={currentPage}/>)
        }
    }

    const showPag2 = () => {
        if(listData.length > dataPerPage && Math.ceil(listData.length / dataPerPage) > 5) {
            return pageNumber.slice(Math.ceil(listData.length / dataPerPage) - 3).map(value => <Pag key={value} pageClick={pageClick} value={value} currentPage={currentPage}/>)
        }
    }

    const download = () => {
        const csvValue = []
        listData.forEach(e => {
            let value = Object.values(e)
            csvValue.push([value[6]])
        })
        const csvTitle = ['Email Address', 'Email Address 2']
        csvValue.unshift(csvTitle);
        let csvContent = "data:text/csv;charset=utf-8," 
        + csvValue.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data_unactive.csv");
        document.body.appendChild(link);
        link.click();
    }

    const handleSelect = (e) => {
        adminSearch(e.target.value);
        setCurrentPage(1);
    }


    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="row">
                        <div className="col-md-4">    
                            <select onChange={onChangeHandler} name="page" className="form-control">
                                <option value="10">Hiển thị 10 dòng</option>
                                <option value="25">Hiển thị 25 dòng</option>
                                <option value="50">Hiển thị 50 dòng</option>
                            </select>
                        </div>
                        <div className="col-md-4 user_select">
                            {user && user.email === "lytuanquang@gmail.com" &&
                                <select onChange={handleSelect} className="form-control">
                                    <option value=''>Hiển thị tất cả user</option>
                                    {dataSelect.map(value => {
                                        return <option key={value}>{value}</option>
                                    })}
                                </select>
                            }
                            {user && user.email === "phuong.tl2@cloudpbx.vn" &&
                                <select onChange={handleSelect} className="form-control">
                                    <option value=''>Hiển thị tất cả user</option>
                                    {dataSelect.map(value => {
                                        return <option key={value}>{value}</option>
                                    })}
                                </select>
                            }
                        </div>
                        <div className="col-md-3"></div>
                        <div className="col-md-1 text-right">    
                            <i role="button" onClick={() => download()} style={{color: '#007546', fontSize: '20px'}} className="fas fa-file-csv mt-1 mr-1 d-none d-sm-block"></i>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped table-bordered for-pc" id="dataTable" width="100%" cellSpacing={0}>
                            <thead className="thead-dark">
                                <tr>
                                    <th>Tên cty viết tắt</th>
                                    <th>Tên dịch vụ</th>
                                    <th>Ngày khởi tạo</th>
                                    <th>Ngày hết hạn</th>
                                    <th>Thời hạn</th>
                                    <th>Gói dịch vụ</th>
                                    <th>Đơn giá</th>
                                    <th>Giá tiền</th>
                                    <th>Extension</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTodos.map(value => <DeleteTableRow user={user} key={value.id} value={value} editClick={() => editClick(value)} delClick={() => delClick(value.id, value.is_cancel)} adminClick={() => adminClick(value.id)}/>)}   
                            </tbody>
                        </table>
                        <table className="table table-striped table-bordered for-phone" width="100%" cellSpacing={0}>
                            <thead className="thead thead-dark">
                                <tr>
                                    <th>Tên cty viết tắt</th>
                                    <th>Thời hạn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentTodos.map(value => <DeleteTableRowPhone key={value.id} value={value} delClick={() => delClick(value.id, value.is_cancel)}/>)}   
                            </tbody>
                        </table>
                        {listData.length > 1 && <div className="pagination-1 float-left">
                               Hiển thị {indexOfFirstNews + 1} đến {indexOfLastNews > listData.length ? listData.length : indexOfLastNews} trên tổng {listData.length} khách hàng.
                        </div>}
                        <div className="pagination-2 float-right">
                            {currentPage !== 1 && Math.ceil(listData.length / dataPerPage) > 6 && <button onClick={() => setCurrentPage(currentPage - 1)} className="btn btn-outline-info" type="submit">Prev</button>}
                            {showPag()}
                            {Math.ceil(listData.length / dataPerPage) < 7 ? "" : currentPage < 4 || currentPage > Math.ceil(listData.length / dataPerPage) - 3 ? <button type="button" className="btn btn-outline-info ml-2">...</button> : <button type="button" className="btn btn-outline-info active ml-2">{currentPage}</button>}
                            {showPag2()}
                            {currentPage !== Math.ceil(listData.length / dataPerPage) && Math.ceil(listData.length / dataPerPage) > 6 && <button onClick={() => setCurrentPage(currentPage + 1)} className="btn btn-outline-info ml-2" type="submit">Next</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DelTable;