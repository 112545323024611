import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, signInWithEmailAndPassword } from '../firebaseConn';
import { useAuthState } from "react-firebase-hooks/auth";
import { ToastContainer } from 'react-toastify';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) navigate('/')
        if (loading) {
          // maybe trigger a loading screen
          return;
        }
    }, [user, loading, navigate]);

    const handleEnterPress = useCallback(event => {
        if(event.keyCode === 13) {
            signInWithEmailAndPassword(email, password)
        }
    }, [email, password]);

    useEffect(() => {
        window.addEventListener('keyup', handleEnterPress);
        return () => {
            window.removeEventListener('keyup', handleEnterPress);
        }
    }, [handleEnterPress])

    return (
        <div className="container login">
            <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                    <div className="col-lg-6">
                        <div className="p-5">
                        <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                        </div>
                        <form className="user">
                            <div className="form-group">
                                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control form-control-user" autoComplete="email" placeholder="Enter Email..." />
                            </div>
                            <div className="form-group">
                                <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control form-control-user" autoComplete="current-password" placeholder="Password" />
                            </div>
                            <button onClick={() => signInWithEmailAndPassword(email, password)} type="reset" className="btn btn-primary btn-user btn-block">Login</button>
                        </form>
                        <hr />
                        <div className="text-center">
                            <Link className="small" to="/forgot">Forgot Password?</Link>
                        </div>
                        <div className="text-center">
                            <Link className="small" to="/register">Create an Account!</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Login;