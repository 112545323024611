import { useEffect, useState} from 'react';
import './App.css';
import AddCustomer from "./Components/AddCustomer";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Search from "./Components/Search";
import Table from "./Components/Table";
import { firebaseConn, auth } from './firebaseConn';
import DelTable from "./Components/DelTable";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import emailjs from '@emailjs/browser';
import{ init } from '@emailjs/browser';
init("wBcB3mWzQAAiC7Cel");

function App() {
  const [formStatus, setFormStatus] = useState(false);
  const [listData, setListData] = useState([]);
  const [dataSelect, setDataSelect] = useState([]);
  const [editData, setEditData] = useState({id: ''});
  const [dataSearch, setDataSearch] = useState([]);
  const [linkStatus, setLinkStatus] = useState(false);
  const [searchStatus, setSearchStatus] = useState(false);
  const customerData = firebaseConn.database().ref('customer');
  
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return navigate("/login");
    if (loading) 
      // maybe trigger a loading screen
    return;
    else {
      customerData.on('value', (snapshot) => {
        let arr = [];
        snapshot.forEach(value => {
          if(value.val().user === user.email) {
            const key = value.key;
            const obj = value.val();
            obj.id = key;
            arr.push(obj);
          } else if(user.email === "phuong.tl2@cloudpbx.vn" && (value.val().user === "lien.nn@cloudpbx.vn" || value.val().user === "ngan.lnn@cloudpbx.vn" || value.val().user === "anh.ldt@cloudpbx.vn")) {
            const key = value.key;
            const obj = value.val();
            obj.id = key;
            arr.push(obj);
          } else if(user.email === "lytuanquang@gmail.com") {
            const key = value.key;
            const obj = value.val();
            obj.id = key;
            arr.push(obj);
          }
        })
        setListData(arr)
        setDataSearch(arr)
      })
    }
  }, [user, loading, navigate]);

  const showForm = () => {
      setFormStatus(!formStatus);
      setEditData({id: ''})
      window.scrollTo(0,195);
  }

  const notify = (title) => toast.success(title, {
    position: "top-right",
    autoClose: 3000
  });

  const submitClick = (dataForm) => {
      dataForm.user = user.email;
      customerData.push(dataForm);
      notify("Thêm thành công !");
  }

  const submitEditClick = (dataForm) => {
      dataForm.total = dataForm.cost * dataForm.payment * dataForm.agent;
      customerData.child(dataForm.id).update({
          sub: dataForm.sub,
          cus_name: dataForm.cus_name,
          cus_email: dataForm.cus_email,
          cus_phone: dataForm.cus_phone,
          address : dataForm.address,
          com_phone: dataForm.com_phone,
          service: dataForm.service,
          create_date: dataForm.create_date,
          exp_date: dataForm.exp_date,
          isp: dataForm.isp,
          payment: dataForm.payment,
          cost: dataForm.cost,
          total: dataForm.total,
          ext: dataForm.ext,
          com_name: dataForm.com_name,
          note: dataForm.note,
          hd_email: dataForm.hd_email,
          agent: dataForm.agent,
          contact_name: dataForm.contact_name
      })
      setEditData({id: ''});
      setFormStatus(false);
      notify("Cập nhật thành công !");
  }

  const editDateClick = (id, expDate) => {
      customerData.child(id).update({
          exp_date: expDate
      })
      notify("Cập nhật ngày hết hạn thành công !");
  }
  
  const editClick = (data) => {
      setFormStatus(!formStatus);
      setEditData(data);
      window.scrollTo(0,195);
  }

  const delClick = (id, is_cancel) => {
      customerData.child(id).update({
        is_cancel : !is_cancel
      })
      is_cancel ? notify("Active thành công !") : notify("Unactive thành công !");
  }

  const adminClick = (id) => {
    const confirm = window.confirm("Bạn có chắc chắn xóa?");
    if(confirm) {
      customerData.child(id).remove()
    }
    notify("Xóa thành công !");
  }

  const thanksClick = (id) => {
    const confirm = window.confirm("Bạn có chắc chắn gửi email cho khách hàng để cảm ơn việc gia hạn dịch vụ Hosting?");
    if(confirm) {
      listData.forEach(value => {
        if(value.id === id && value.service.slice(0,7) === 'Hosting') {
          var templateParams = {
            cus_email: value.cus_email,
            service: value.service,
            payment: value.payment,
            exp_date: moment(value.exp_date).format("DD/MM/YYYY"),
            contact_name: value.contact_name
          };
          
          emailjs.send('service_3zx0enb', 'template_gykbrwc', templateParams)
              .then(function(response) {
                notify("Gửi email thành công!");
              }, function(error) {
                alert('Gửi email thất bại !!!', error);
              });
        }
        else if(value.id === id && value.service.slice(0,4) === 'Thue') {
          var templateParams = {
            cus_email: value.cus_email,
            service: value.service,
            payment: value.payment,
            exp_date: moment(value.exp_date).format("DD/MM/YYYY"),
            contact_name: value.contact_name
          };
          
          emailjs.send('service_3zx0enb', 'template_tcad9dh', templateParams)
              .then(function(response) {
                notify("Gửi email thành công!");
              }, function(error) {
                alert('Gửi email thất bại !!!', error);
              });
        }
      })
    }
  }

  const search = (txtSearch) => {
    let data = []
    listData.forEach((value) => {
      if(value.cus_name.toUpperCase().indexOf(txtSearch.toUpperCase()) !== -1 || value.service.toUpperCase().indexOf(txtSearch.toUpperCase()) !== -1) {
         data.push(value)
      }
    })
    setDataSearch(data)
    setSearchStatus(!searchStatus);
  }

  useEffect(() => {
      const data = [];
      const userList = [];
      listData.forEach(value => {
          data.push(value.user)
      })
      data.filter((item, index) => {
          if(data.indexOf(item) === index === true)
              userList.push(item);
      });
      setDataSelect(userList);
  }, [listData]);

  const adminSearch = (e) => {
    let data = []
    listData.forEach((value) => {
      if(value.user.toUpperCase().indexOf(e.toUpperCase()) !== -1) {
         data.push(value)
      }
    })
    setDataSearch(data)
  }

  const showTable = () => {
    let data = [];
    dataSearch.forEach((value) => {
      if(value.is_cancel === false) {
        data.unshift(value)
      }
    })
    if(linkStatus === false) {
      return <Table user={user} dataSelect={dataSelect} adminSearch={adminSearch} onDateClick={editDateClick} searchStatus={searchStatus} editClick={editClick} delClick={delClick} adminClick={adminClick} thanksClick={thanksClick} listData={data}/>
    }
  }

  const showDelTable = () => {
    let data = [];
    dataSearch.forEach((value) => {
      if(value.is_cancel) {
        data.unshift(value)
      } 
    })
    if(linkStatus) {
      return <DelTable user={user} dataSelect={dataSelect} adminSearch={adminSearch} searchStatus={searchStatus} editClick={editClick} delClick={delClick} adminClick={adminClick} listData={data}/>
    }
  }

  const linkClick = () => {
      setLinkStatus(!linkStatus)
  }

  const showBtnLink = () => {
    if(linkStatus) {
      return <button onClick={linkClick} className="btn btn-dark" type="submit">DANH SÁCH UNACTIVE</button>
    } else {
      return <button onClick={linkClick} className="btn btn-blue" type="submit">DANH SÁCH ACTIVE</button>
    }
  }

  const handleLogo = () => {
    window.location.reload()
  }

  return (
    <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
            <Header user={user} onLogo={handleLogo}/>
            <Search search={search} />
            <div className="container-fluid">
              <div className="card shadow mb-4">
                {showBtnLink()}
              </div>  
            </div>
            <AddCustomer editData={editData} submitClick={submitClick} submitEditClick={submitEditClick} showForm={showForm} formStatus={formStatus}/>
            {showTable()}
            {showDelTable()}
            <ToastContainer />
        </div>
        <Footer />
    </div>
  );
}

export default App;
