import React, { useState, useEffect } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

function AddCustomer({showForm, formStatus, submitClick, submitEditClick, editData}) {

    const initialState = {
        sub: '',
        cus_name:'',
        cus_phone:'',
        cus_email:'',
        address: '',
        com_phone: '',
        service: '',
        create_date: '',
        exp_date: '',
        isp: '',
        payment: 0,
        cost: 0,
        total: 0,
        ext: '',
        com_name: '',
        note: '',
        hd_email: '',
        is_cancel: false,
        user: '',
        agent: 1,
        contact_name: ''
    }

    const [dataForm, setDataForm] = useState(initialState);

    const onChangeHandler = ({target: {name,value}}) => 
        setDataForm(prevState => ({...prevState, [name]: value}));
        console.log(dataForm)

    const handleChange = (content) => {
        setDataForm(prevState => ({...prevState, note: content}))
    }

    useEffect(() => {
        if(editData.id === '') {
            // setDataForm(editData[0])   
        } else {
            setDataForm(editData);
        }
    },[editData])

    const btnSubmitClick = () => {
        if(editData.id === '') {
            if(dataForm.cus_name !== '' && dataForm.service !== '' && dataForm.exp_date !== '') {
                dataForm.total = dataForm.payment * dataForm.cost * dataForm.agent;
                submitClick(dataForm);   
                setDataForm(initialState)
                showForm()
            } else {
                setDataForm(initialState)
                alert("Vui lòng nhập thông tin!")
            }
        } else {
            submitEditClick(dataForm)
            setDataForm(initialState)
        }
    }

    const addClick = () => {
        showForm();
        setDataForm(initialState);
    }

    const showAddForm = () => {
        if(formStatus) {
            return(
                <div className="card-body">
                    <form>
                        <div className="form-row">
                            <div className="col-md-2 mb-3">
                                <label>Mã hợp đồng</label>
                                <input defaultValue={editData.sub} onChange={onChangeHandler} name="sub" type="text" className="form-control"/>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Tên công ty viết tắt <span style={{color: 'red'}}>(*)</span></label>
                                <input defaultValue={editData.cus_name} onChange={onChangeHandler} name="cus_name" type="text" className="form-control" />
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Tên khách hàng </label>
                                <input defaultValue={editData.contact_name} onChange={onChangeHandler} name="contact_name" type="text" className="form-control" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label>Email khách hàng</label>
                                <input defaultValue={editData.cus_email} onChange={onChangeHandler} name="cus_email" placeholder="quang@gmail.com, quang@voipmart.vn" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-2 mb-3">
                                <label>Điện thoại khách hàng</label>
                                <input defaultValue={editData.cus_phone} onChange={onChangeHandler} name="cus_phone" type="text" className="form-control" />
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Địa chỉ</label>
                                <input defaultValue={editData.address} onChange={onChangeHandler} name="address" type="text" className="form-control" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label>Điện thoại công ty</label>
                                <input defaultValue={editData.com_phone} onChange={onChangeHandler} name="com_phone" type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-3 mb-3">
                                <label>Tên dịch vụ <span style={{color: 'red'}}>(*)</span></label>
                                <input defaultValue={editData.service} onChange={onChangeHandler} name="service" placeholder="CPBX, CXCS, API, GA ..."  type="text" className="form-control"/>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Ngày khởi tạo </label>
                                <input defaultValue={editData.create_date} onChange={onChangeHandler} name="create_date" type="date" className="form-control"/>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>Ngày hết hạn <span style={{color: 'red'}}>(*)</span></label>
                                <input defaultValue={editData.exp_date} onChange={onChangeHandler} name="exp_date" type="date" className="form-control"/>
                            </div>
                            <div className="col-md-3 mb-3">
                                <label>ISP Telecom</label>
                                <input defaultValue={editData.isp} onChange={onChangeHandler} name="isp" type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-2 mb-3">    
                                <label>Gói dịch vụ (tháng)</label>
                                  {/* <select onChange={onChangeHandler} name="payment" defaultValue={editData.payment} className="form-control">
                                    <option value={3}>3 tháng</option>
                                    <option value={6}>6 tháng</option>
                                    <option value={12}>12 tháng</option>
                                  </select> */}
                                <input defaultValue={editData.payment} onChange={onChangeHandler} placeholder="Nhập số lượng" name="payment" type="number" className="form-control" />
                            </div>
                            <div className="col-md-2 mb-3">   
                                <label>Agent</label>
                                <input defaultValue={editData.agent} onChange={onChangeHandler} placeholder="Nhập số lượng agent" name="agent" type="number" className="form-control"/>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="validationDefault12">Đơn giá </label>
                                <input defaultValue={editData.cost} onChange={onChangeHandler} name="cost" type="number" className="form-control" />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label htmlFor="validationDefault13">Extension </label>
                                <input defaultValue={editData.ext} onChange={onChangeHandler} name="ext" placeholder="5 ext, 10 agent, 3 api ..." type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-6 mb-3">
                                <label htmlFor="validationDefault14">Tên công ty</label>
                                <input defaultValue={editData.com_name} onChange={onChangeHandler} name="com_name" type="text" className="form-control" />
                            </div>
                            {/* <div className="col-md-3 mb-3">
                                <label htmlFor="validationDefault15">Ghi chú thêm</label>
                                <input defaultValue={editData.note} onChange={onChangeHandler} name="note" type="text" className="form-control" />
                            </div> */}
                            <div className="col-md-6 mb-3">
                                <label htmlFor="validationDefault16">Email hóa đơn</label>
                                <input defaultValue={editData.hd_email} onChange={onChangeHandler} name="hd_email" type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12 mb-3">
                                <label>Ghi chú thêm:</label>
                                <SunEditor onChange={handleChange} defaultValue={editData.note}/>
                                {/* <textarea onChange={onChangeHandler} name="note" className="form-control" rows={3} defaultValue={editData.note} /> */}
                            </div>
                        </div>
                        {editData.cus_name !== undefined ? dataForm.cus_name !== '' && dataForm.service !== '' && dataForm.exp_date !== '' ? <button onClick={btnSubmitClick} className="btn btn-success" type="reset">Cập nhật</button> : <button onClick={btnSubmitClick} className="btn btn-success" type="button" disabled="disable">Cập nhật</button> : dataForm.cus_name !== '' && dataForm.service !== '' && dataForm.exp_date !== '' ? <button onClick={btnSubmitClick} className="btn btn-primary" type="reset">Xác nhận</button> : <button onClick={btnSubmitClick} className="btn btn-primary" type="button" disabled="disable">Xác nhận</button>}
                        {formStatus && <button onClick={() => addClick()} className="btn btn-danger ml-2" type="button">Hủy</button>}
                    </form>
                </div>
            );
        }
    } 

    return (
        <div className="container-fluid">
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    {formStatus ? editData.cus_name !== undefined ? <b>Sửa thông tin khách hàng</b>: <b>Thêm mới khách hàng</b> : <button onClick={() => addClick()} className="btn btn-primary" type="button">Thêm mới</button>} 
                </div>
                {showAddForm()}
            </div>
        </div>
    );
}

export default AddCustomer;