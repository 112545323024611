import React, { useCallback, useEffect, useState } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, registerWithEmailAndPassword } from '../firebaseConn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Register(props) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const notify = (title) => toast.warning(title, {
        position: "top-right",
        autoClose: 3000
    });
    
    const register = useCallback(() => {
        if (!name || !email || !password) {
            notify("Vui lòng nhập dữ liệu");
        } else {
            registerWithEmailAndPassword(name, email, password);
        }
    }, [name, email, password]);

    useEffect(() => {
        if (user) navigate("/"); 
        if (loading) return;
      }, [user, loading, navigate]);

    const handleEnterPress = useCallback(event => {
        if(event.keyCode === 13) {
            register()
        }
    }, [register]);

    useEffect(() => {
        window.addEventListener('keyup', handleEnterPress);
        return () => {
            window.removeEventListener('keyup', handleEnterPress);
        }
    }, [handleEnterPress])

    return (
        <div className="container">
            <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                <div className="col-lg-5 d-none d-lg-block bg-register-image" />
                <div className="col-lg-7">
                    <div className="p-5">
                    <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Create an Account!</h1>
                    </div>
                    <form className="user">
                        <div className="form-group">
                            <input onChange={(e) => setName(e.target.value)} type="text" className="form-control form-control-user" placeholder="Full Name" />
                        </div>
                        <div className="form-group">
                        <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control form-control-user" autoComplete='email' placeholder="Email Address" />
                        </div>
                        <div className="form-group">
                            <input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control form-control-user" autoComplete="current-password" placeholder="Password" />
                        </div>
                        <button onClick={register} type="reset" className="btn btn-primary btn-user btn-block">
                            Register Account
                        </button>
                        {/* <hr />
                        <button onClick={signInWithGoogle} type="reset" className="btn btn-google btn-user btn-block">
                        <i className="fab fa-google fa-fw" /> Register with Google
                        </button> */}
                    </form>
                    <hr />
                    <div className="text-center">
                        <Link className="small" to="/forgot">Forgot Password?</Link>
                    </div>
                    <div className="text-center">
                        <Link className="small" to="/login">Already have an account? Login!</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Register;