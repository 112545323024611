import React, { useState } from 'react';
import moment from 'moment';

function TableRow({user, value, onDateClick, delClick, thanksClick}) {

    const [iconPlus, setIconPlus] = useState(false)
    const [editDateStatus, setEditDateStatus] = useState(false);
    const [expDate, setExpDate] = useState(value.exp_date)

    const saveDateClick = () => {
        onDateClick(value.id, expDate);
        setEditDateStatus(!editDateStatus);
    }

    return (
        <>
            <tr>
                <td>{iconPlus ? <i onClick={() => setIconPlus(!iconPlus)} role="button" className="fas fa-minus-circle text-primary"></i> : <i onClick={() => setIconPlus(!iconPlus)} role="button" className="fas fa-plus-circle"></i>} &nbsp; {value.cus_name}</td>    
                {value.times < 15 ? <td style={{color: '#FF3A25'}}><b>{value.times}</b></td> : <td>{value.times}</td> } 
            </tr>
            {iconPlus &&
                <tr className='child'>
                    <td colSpan={2}>
                        <ul className="dtr-details">
                            <li><span className="dtr-title">Tên dịch vụ</span> <span className="dtr-data">{value.service}</span></li>
                            <li><span className="dtr-title">Khởi tạo</span> <span className="dtr-data">{moment(value.create_date).format("DD/MM/YYYY")}</span></li>
                            <li><span className="dtr-title">Hết hạn</span> <span className="dtr-data">{editDateStatus ? <><input onChange={(e) => setExpDate(e.target.value)} defaultValue={value.exp_date} type="date" /> <i role='button' onClick={() => saveDateClick()} className="fas fa-save"></i></> : <>{moment(value.exp_date).format("DD/MM/YYYY")} <i onClick={() => setEditDateStatus(!editDateStatus)} role='button' className="fas fa-edit"></i></>} </span></li>
                            <li><span className="dtr-title">Gói dịch vụ</span> <span className="dtr-data">{value.payment === '' ? '0' : value.payment + " tháng" }</span></li>
                            <li><span className="dtr-title">Đơn giá</span> <span className="dtr-data">{new Intl.NumberFormat().format(value.cost)}</span></li>
                            <li><span className="dtr-title">Giá tiền</span> <span className="dtr-data">{new Intl.NumberFormat().format(value.total)}</span></li>
                            <li><span className="dtr-title">Extension</span> <span className="dtr-data">{value.ext}</span></li>
                            <li><span className="dtr-title">Mã hđ</span> <span className="dtr-data">{value.sub}</span></li>
                            <li><span className="dtr-title">ISP</span> <span className="dtr-data">{value.isp}</span></li>
                            <li><span className="dtr-title">Tên KH</span> <span className="dtr-data">{value.contact_name}</span></li>
                            <li><span className="dtr-title">Email KH</span> <span className="dtr-data">{value.cus_email}</span></li>
                            <li><span className="dtr-title">SĐT KH</span> <span className="dtr-data">{value.cus_phone}</span></li>
                            <li><span className="dtr-title">SĐT Cty</span> <span className="dtr-data">{value.com_phone}</span></li>
                            <li><span className="dtr-title">Tên Cty</span> <span className="dtr-data">{value.com_name}</span></li>
                            <li><span className="dtr-title">Email hđ</span> <span className="dtr-data">{value.hd_email}</span></li>
                            <li><span className="dtr-title">Địa chỉ</span> <span className="dtr-data">{value.address}</span></li>
                            <li><span className="dtr-title">Ghi chú</span> <span className="dtr-data"><span dangerouslySetInnerHTML={{__html: value.note}} /></span></li>
                            <li><span className="dtr-title">Action</span> <span className="dtr-data">
                                <button onClick={() => delClick()} className="btn btn-danger ml-2" type="submit">Unactive</button>
                                {user && user.email === "lytuanquang@gmail.com" && <button onClick={() => thanksClick()} className="btn btn-warning ml-2" type="button">Thanks</button>}
                            </span></li>
                        </ul>
                    </td>
                </tr>
            }
        </>    
    );
}

export default TableRow;