import React, { useState } from 'react';
import moment from 'moment';

function TableRow({user, onDateClick, value, editClick, delClick, adminClick, thanksClick}) {

    const [showStatus, setShowStatus] = useState(false);
    const [editDateStatus, setEditDateStatus] = useState(false);
    const [expDate, setExpDate] = useState(value.exp_date);

    const saveDateClick = () => {
        onDateClick(value.id, expDate);
        setEditDateStatus(!editDateStatus);
    }

    const showChiTiet = () => {
        if(showStatus) {
            return (
                <>
                    <tr className="tr-blue">
                        <th>Mã hợp đồng</th>
                        <th>ISP</th>
                        <th>Tên KH</th>
                        <th>Email khách hàng</th>
                        <th>SĐT KH</th>
                        <th>SĐT công ty</th>
                        <th>Tên công ty</th>
                        <th>Email hóa đơn</th>
                        <th>Địa chỉ</th>
                        <th>Ghi chú</th>
                    </tr>
                    <tr>
                        {/* <td className="btn-group mr-2 border_group" role="group">{showStatus ? <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-secondary">Close</button>
                                    : <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-primary">Detail</button>
                        }<button onClick={() => editClick()} className="btn btn-info ml-2" type="button">Edit</button>
                        </td> */}
                        <td>{value.sub}</td>
                        <td>{value.isp}</td>
                        <td>{value.contact_name}</td> 
                        <td>{value.cus_email}</td>
                        <td>{value.cus_phone}</td>
                        <td>{value.com_phone}</td>
                        <td>{value.com_name}</td>
                        <td>{value.hd_email}</td>
                        <td>{value.address}</td>
                        <td><span dangerouslySetInnerHTML={{__html: value.note}} /></td>
                    </tr>
                </>
            )
        }
    }

    return (
        <>
            <tr>
                <td>{value.cus_name}</td>
                <td>{value.service}</td>
                <td>{moment(value.create_date).format("DD/MM/YYYY")}</td>
                {editDateStatus ? <td><input onChange={(e) => setExpDate(e.target.value)} defaultValue={value.exp_date} type="date" /> <i role='button' onClick={() => saveDateClick()} className="fas fa-save"></i></td> : <td>{moment(value.exp_date).format("DD/MM/YYYY")} <i onClick={() => setEditDateStatus(!editDateStatus)} role='button' className="fas fa-edit"></i></td>}  
                {value.times < 15 ? <td style={{color: '#FF3A25'}}><b>{value.times}</b></td> : <td>{value.times}</td> } 
                <td>{value.payment === '' ? '0' : value.payment + " tháng" }</td>
                <td>{new Intl.NumberFormat().format(value.cost)}</td>
                <td>{new Intl.NumberFormat().format(value.total)}</td>
                <td>{value.ext}</td>
                <td>
                    <div className="btn-group mr-2" role="group">
                        {showStatus ? <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-secondary">Close</button>
                                    : <button onClick={() => setShowStatus(!showStatus)} type="button" className="btn btn-primary">Detail</button>
                        }
                        <button onClick={() => editClick()} className="btn btn-info ml-2" type="button">Edit</button>
                        <button onClick={() => delClick()} className="btn btn-danger ml-2" type="submit">Unactive</button>
                        {user && user.email === "lytuanquang@gmail.com" && <button onClick={() => thanksClick()} className="btn btn-warning ml-2" type="button">Thanks</button>}
                        {user && user.email === "lytuanquang@gmail.com" && <button onClick={() => adminClick()} className="btn btn-outline-dark ml-2" type="button">Delete</button>}
                    </div>
                </td>
            </tr>
            {showChiTiet()}
        </>
    );
}

export default TableRow;