import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback  } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, sendPasswordResetEmail } from '../firebaseConn';
import { ToastContainer } from 'react-toastify';

function Forgot() {

    const [email, setEmail] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    
    useEffect(() => {
        if (user) navigate('/');
        if (loading) return;
    }, [user, loading, navigate]);

    const handleEnterPress = useCallback(event => {
        if(event.keyCode === 13) {
            sendPasswordResetEmail(email)
        }
    }, [email]);

    useEffect(() => {
        window.addEventListener('keyup', handleEnterPress);
        return () => {
            window.removeEventListener('keyup', handleEnterPress);
        }
    }, [handleEnterPress])

    return (
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
                <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                    <div className="row">
                    <div className="col-lg-6 d-none d-lg-block bg-password-image" />
                    <div className="col-lg-6">
                        <div className="p-5">
                        <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-2">Quên mật khẩu?</h1>
                            <p className="mb-4">Chỉ cần nhập địa chỉ email của bạn vào bên dưới và chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn!</p>
                        </div>
                        <form className="user">
                            <div className="form-group">
                                <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email Address..." />
                            </div>
                            <button onClick={() => sendPasswordResetEmail(email)} type="reset" className="btn btn-primary btn-user btn-block">
                                Reset Password
                            </button>
                        </form>
                        <hr />
                        <div className="text-center">
                            <Link className="small" to="/register">Create an Account!</Link>
                        </div>
                        <div className="text-center">
                            <Link className="small" to="/login">Already have an account? Login!</Link>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Forgot;